.cs-app {
  margin-top: 80px;
}

.cs-table-row-bg {
  background-color: #0a6ed1;
  color: white
}

.cs-header-icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cs-hide-empty:empty {
  display: none;
}

.cs-pointer {
  cursor: pointer;
}

.cs-primary-bg {
  background-color: #0a6ed1;
}

.cs-login-title {
  text-align: center;
}

.cs-login-title img {
  width: 15em;
  height: 15em;
  margin-top: 5%;
}

.cs-login-title h2 {
  margin-top: 5%;
  margin-bottom: 5%;
}

.cs-calendar {
  position: absolute;
  z-index: 1000;
}

.cs-dark {
  color: black;
}